/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import BaseChip from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { checkNumber } from '../library/number'
const { useEffect, useState } = React;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const normalizeNumber = (num) => {
  const addUp = `${num}`.split('').reduce((acum, next) => {
    return acum + Number(next)
  }, 0)
  if (`${addUp}`.length === 1) {
    return addUp
  }
  return normalizeNumber(addUp)
}



const Chip = ({ children, num, size }) => {
  let props = {}
  if (size) {
    props = {
      width: size,
      height: size,
    }
  }
  return <BaseChip sx={{ bgcolor: checkNumber(num).color, ...props }}> {children} </BaseChip>
}


function Seo({ description, lang, meta, title, birthday, onNumber }) {

  const [expanded, setExpanded] = React.useState('panel0');
  const [numOfElement, setNumOfElement] = useState(null)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { day, month, year } = birthday;
  const dateString = `${day}${month}${year}`
  const [a, b, c, d, e, f, g, h] = dateString.split('')
  const [i, j, k, l] = [`${a}${b}`, `${c}${d}`, `${e}${f}`, `${g}${h}`].map(e => normalizeNumber(e))
  const [m, n] = [`${i}${j}`, `${k}${l}`].map(e => normalizeNumber(e))
  const [o] = [`${m}${n}`].map(e => normalizeNumber(e))
  const [q, p] = [`${m}${o}`, `${m}${o}`].map(e => normalizeNumber(e))
  const [r] = [`${q}${p}`].map(e => normalizeNumber(e))
  const [t, s] = [`${i}${m}`, `${j}${m}`].map(e => normalizeNumber(e))
  const u = [`${t}${s}`].map(e => normalizeNumber(e))
  const [v, w] = [`${k}${n}`, `${l}${n}`].map(e => normalizeNumber(e))
  const x = [`${v}${w}`].map(e => normalizeNumber(e))

  useEffect(() => {
    if(i && j && k && l && m && n && o) {
      const _numOfElement = [i, j, k, l, m, n, o].reduce((acum, next) => {
  
        const number = checkNumber(next)
        console.log(number.element)
        acum[number.element] = acum[number.element] + 1;
        return acum
      }, {
        water: 0,
        earth: 0,
        metal: 0,
        fire: 0,
        wood: 0
      })
      setNumOfElement(_numOfElement)
    }
  }, [i, j, k, l, m, n, o])


  useEffect(() => {
    if(numOfElement) {
      onNumber && onNumber({
        elements: numOfElement
      })
    }
    
  }, [numOfElement])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="center" spacing={3}>
          {
            [r].map(e => <Chip num={e}>{e}</Chip>)
          }

        </Stack>

        <Stack direction="row" justifyContent="center" spacing={3}>

          {
            [q, p].map(e => <Chip num={e}>{e}</Chip>)
          }

        </Stack>

      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack direction="row" spacing={3} sx={{ paddingRight: '8px' }}>

          {
            [u, t, s].map(e => <Chip num={e}>{e}</Chip>)
          }

        </Stack>
        <Stack direction="row" spacing={3} sx={{ paddingLeft: '8px' }}>

          {
            [v, w, x].map(e => <Chip num={e}>{e}</Chip>)
          }

        </Stack>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          主性格
        </Box>
        <Stack direction="row" justifyContent="center" spacing={3}>
          {
            [o].map(e => <Chip num={e}>{e}</Chip>)
          }


        </Stack>
        <Stack direction="row" justifyContent="center" spacing={3}>
          {
            [m, n].map(e => <Chip num={e}>{e}</Chip>)
          }
        </Stack>

        <Stack direction="row" justifyContent="center" spacing={3}>
          {
            [i, j, "魄", k, l].map(e => <Chip num={e}> {e}</Chip>)
          }
        </Stack>
      </Box>
      <Stack direction="row" justifyContent="center" spacing={3}>
        {
          //[a, b, c, d, e, f, g, h].map(e => <Chip >{e}</Chip>)
        }
      </Stack>
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
