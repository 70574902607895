/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { analyseNumber } from '../library/number'
const { useEffect, useState } = React;


function Seo({ number, description, lang, meta, title, birthday, onNumber }) {


  useEffect(() => {
    if(number?.length > 1) {
      const result = analyseNumber(number);
      onNumber && onNumber(result)
    }
  }, [number])

  return (
    <>
{
  JSON.stringify()
}
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
