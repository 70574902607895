import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const createData = (
    name,
    calories,
    fat,
    carbs,
    protein,
) => {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('天医 财富', "13/31", "86/68", "49/94", "27/72"),
    createData('生气 人员', "14/41", "76/76", "39/93", "28/82"),
    createData('延年 成功', "19/91", "87/78", "34/43", "26/62"),
    createData('伏位 等待', "11/22", "99/88", "77/66", "33/44"),
    createData('五鬼 凶险', "18/81", "97/79", "36/46", "42/24"),
    createData('六煞 桃花', "16/61", "74/47", "38/48", "92/29"),
    createData('祸害 病痛 ', "17/71", "89/98", "46/64", "32/23"),
    createData('绝命 孤独 ', "12/21", "69/96", "48/84", "37/73"),
];

export default function BasicTable() {
    return (
        <TableContainer component={Paper}  sx={{ maxWidth: 650 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>数字星/磁场</TableCell>
                        <TableCell align="right">最强</TableCell>
                        <TableCell align="right">强</TableCell>
                        <TableCell align="right">弱</TableCell>
                        <TableCell align="right">最弱</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}