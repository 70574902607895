const combo = {
  "shengqi1": ["14", "41"],
  "shengqi2": ["67", "76"],
  "shengqi3": ["39", "93"],
  "shengqi4": ["28", "82"],

  "tianyi1": ["13", "31"],
  "tianyi2": ["68", "86"],
  "tianyi3": ["49", "94"],
  "tianyi4": ["27", "72"],

  "yannian1": ["19", "91"],
  "yannian2": ["78", "87"],
  "yannian3": ["34", "43"],
  "yannian4": ["26", "62"],

  "fuwei1": ["11", "22"],
  "fuwei2": ["88", "99"],
  "fuwei3": ["66", "77"],
  "fuwei4": ["33", "44"],

  "liusha1": ["16", "61"],
  "liusha2": ["47", "74"],
  "liusha3": ["38", "83"],
  "liusha4": ["29", "92"],

  "huohai1": ["17", "71"],
  "huohai2": ["89", "98"],
  "huohai3": ["46", "64"],
  "huohai4": ["23", "32"],

  "wugui1": ["18", "81"],
  "wugui2": ["79", "97"],
  "wugui3": ["36", "63"],
  "wugui4": ["24", "42"],

  "jueming1": ["12", "21"],
  "jueming2": ["69", "96"],
  "jueming3": ["48", "84"],
  "jueming4": ["37", "73"],

  "good05": ["01", "03", "05", "06", "07", "08", "15", "25", "30", "35", "45", "51", "52", "57", "58", "65", "75", "85", "95"],
  "bad05": ["02", "04", "09", "10", "20", "40", "50", "53", "54", "55", "56", "59", "60", "70", "80", "90"]

}

const GOOD_NUMBER = [
  ...combo["shengqi1"],
  ...combo["shengqi2"],
  ...combo["shengqi3"],
  ...combo["shengqi4"],

  ...combo["tianyi1"],
  ...combo["tianyi2"],
  ...combo["tianyi3"],
  ...combo["tianyi4"],

  ...combo["yannian1"],
  ...combo["yannian2"],
  ...combo["yannian3"],
  ...combo["yannian4"],

  ...combo["fuwei1"],
  ...combo["fuwei2"],
  ...combo["fuwei3"],
  ...combo["fuwei4"],
]

const daJi = [1, 3, 5, 11, 13, 15, 16, 21, 24, 29, 31, 33, 39, 41, 47, 48, 52, 63, 67, 68, 81];

const ji = [6, 7, 8, 17, 18, 25, 32, 35, 37, 45, 57, 65, 73]


const numberToLevel = (pair) => {
  return Object.keys(combo).reduce((prev, next) => {
    if (combo[next].includes(pair)) {
      return {

        num: pair,
        type: next.substr(0, next.length - 1),
        level: next.substr(-1)
      }
    } else {
      return prev;
    }
  }, {

  })
}




const chunk = (arr, len) => {

  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
}


const removeFiveZero = (str) => {

  return str.split("").reduce((prev, next) => {
    if (next !== "0" && next !== "5") {
      return prev + next;
    } else {
      return prev
    }
  }, "")


}

const breakNumber = (str) => {
  const result = []

  const number = removeFiveZero(str)
  for (let i = 0; i < number.length; i++) {
    let subNumber = number[i]
    if (["0", "5"].includes(subNumber) || i === number.length - 1) {
      continue;
    }

    let nextNumberIndex = i + 1;
    while (true) {

      if (nextNumberIndex === number.length) {
        break;
      }
      subNumber = subNumber + number[nextNumberIndex]
      if (!["0", "5"].includes(subNumber[subNumber.length - 1])) {
        break;
      }
      nextNumberIndex++;
    }
    result.push({
      num: subNumber,
      ...numberToLevel(subNumber)
    })
  }
  return result
}




const checkOccurance = (num, str) => {
  const numberArray = num?.split('');
  const chunkArray = chunk(numberArray, str.length)
  return chunkArray.filter(e => e.join('') === str);
}


const checkEnergyDirection = chunk => {
  return ['yannian', 'fuwei', 'huohai', 'shengqi', 'tianyi', 'wugui', 'liusha', 'jueming']
    .reduce((prev, star) => {
      const types = chunk.filter(e => e.type === star);

      if (types.length < 1) {
        prev[star] = 0
      } else {
        prev[star] = Number(types[types.length - 1].level) - Number(types[0].level)
      }
      return prev;
    }, {})
}

const getEnergyFlow = chunk => {
  return ['yannian', 'fuwei', 'huohai', 'shengqi', 'tianyi', 'wugui', 'liusha', 'jueming']
    .reduce((prev, star) => {
      const types = chunk.filter(e => e.type === star);

      if (types.length < 1) {
        prev[star] = []
      } else {
        prev[star] = types.map((e, i) => {
          if (types[i + 1]) {
            return (Number(e.level) - Number(types[i + 1].level)) + ''
          } else {
            return ""
          }
        }).filter(e => e !== "")
      }
      return prev;
    }, {})
}




export const abab = (number) => {

  if (number[7] === number[5] && number[4] === number[6]) {
    return true;
  }
  return false
}

export const ababab = (number) => {

  if (number[7] === number[5] && number[4] === number[6] && number[2] === number[4] && number[3] === number[5]) {
    return true;
  }
  return false
}

export const aaa = (number) => {
  let hasNeedle = false;
  [0, 1, 2, 3, 5, 6, 7, 8, 9].forEach(e => {
    const needle = `${e}${e}${e}`;
    if (number.includes(needle)) {
      hasNeedle = true;
    }
  })
  return hasNeedle;
}

export const hasNDigit = (n, digits) => {
  const digitArr = [0, 1, 2, 3, 5, 6, 7, 8, 9].map(n => {
    return digits.split('').filter(digit => Number(digit) === n).length
  })
  return digitArr.filter(nn => nn != 0).length < n;
}

export const hasTheseNumber = (e, checklist) => {
  const digitArr = e.split('')
  const match = checklist.filter(e => digitArr.includes(e))
  const occurance = digitArr.filter(e => checklist.includes(e)).length
  return match.length === checklist.length && occurance > 6;
}

export const hasNNumber = (number, digit, length) => {
  return number.split('').filter(n => n === digit).length >= length
}

export const uniqueNumber = (number, digit) => {
  return number.split('').reduce((prev, next, i, arr) => prev && arr.filter(e => e === next).length <= 1, true)
}

export const repeatNumber = (num) => {
  const firstThreeNum = num.slice(0, 3);
  return num.slice(3).includes(firstThreeNum)
}

export const repeatExact = (num) => {
  const firstThreeNum = num.slice(0, 3);
  return num.slice(0, 4) === num.slice(4)
}

const checkGroup = (num) => {
  const numberChunk = breakNumber(num);
  const numberOfZero = checkOccurance(num, '0').length;
  const numberOfFive = checkOccurance(num, '5').length;
  const numberOfNegative = numberChunk.filter(n => !GOOD_NUMBER.includes(n.num)).length;

  const energyChart = checkEnergyDirection(numberChunk)
  const { yannian, fuwei, shengqi, tianyi } = energyChart
  const goodEnergyDirection = yannian < 1 && fuwei < 1 && shengqi < 1 && tianyi < 1;
  const goodEndingNumber = GOOD_NUMBER.includes(numberChunk[numberChunk.length - 1].num)
  const badStartingNumber = !GOOD_NUMBER.includes(numberChunk[0].num);

  const hasGoodCombo = !!['413', '41319', '1314', '1413', '191314', '191413', '131419'].filter(n => num.includes(n)).length

  const studyCombo = !!['4319'].filter(n => num.includes(n)).length

  if (numberOfZero === 0 && numberOfFive === 0 && numberOfNegative === 0 && goodEnergyDirection && goodEndingNumber) {
    return 'S'
  }

  if (numberOfZero === 0 && numberOfFive < 2 && numberOfNegative === 0 && goodEnergyDirection && goodEndingNumber) {
    return 'A'
  }
  if (numberOfZero === 0 && numberOfFive < 2 && numberOfNegative === 0 && goodEnergyDirection && goodEndingNumber) {
    return 'B'
  }
  if (numberOfZero === 0 && numberOfFive < 2 && numberOfNegative === 1 && goodEnergyDirection && goodEndingNumber && badStartingNumber) {
    return 'C'
  }
  if (hasGoodCombo && numberOfZero === 0 && goodEndingNumber) {
    return 'CC'
  }

  if (numberOfZero === 0 && numberOfFive < 2 && goodEndingNumber) {
    return 'D'
  }

  return 'U'
}

export const isYiJing = (n) => {
  return ['A', 'B', 'C', 'CC'].includes(checkGroup(n))
}

export const isPureYijing = (n) => {
  return ['S'].includes(checkGroup(n))
}

export const getNumberEnergy = (name) => {
  return Object.keys(combo).filter(e => e.includes(name))
    .reduce((prev, next) => {
      return prev.concat(combo[next])

    }, [])
}



const sortOn = (property) => {
  return function (a, b) {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    } else {
      return 0;
    }
  }
}

const doAnalysis = (chunks) => {
  return [
    { star: "天医", elem: 8, english: 'tianyi' },
    { star: "生气", elem: 3, english: 'shengqi' },
    { star: "延年", elem: 6, english: 'yannian' },
    { star: "伏位", elem: 2, english: 'fuwei' },
    { star: "五鬼", elem: 9, english: 'wugui' },
    { star: "祸害", elem: 2, english: 'huohai' },
    { star: "绝命", elem: 6, english: 'jueming' },
    { star: "六煞", elem: 1, english: 'liusha' },
  ].map(e => {
    return {
      ...e,
      quantity: chunks?.filter(c => c.type === e.english).length,
      description: starCharacteristic[e.english]
    }
  })
    .sort(sortOn('quantity'))
    .reverse()
}

const starCharacteristic = {
  'jueming': "不会守中庸之道、人生际遇像荡秋千般，时高时低不稳定、极端乐观或悲观很两极化、常有出乎意料之外的好事或坏事发生、易有官非发生、具有冒险精神、头脑好、反应快尤其在工作上，有超强企划、判断力，脾气差、无法冷静处理好情绪问题，主观成见深，在团体之中，通常是独来独往，常会吸引朋友的崇拜，但喜欢ㄧ个人独处，情感方面不易有结果，婚后家庭协调能力也较差，不太会和配偶沟通互动或分工，极端个性，波折际遇特质明显，自以为是，是绝命磁场最伤的个性",
  'liusha': "六煞从字面，就是六种煞气的意思，在地理环境学中，物体或者空间的形状，所产生的影响力，都称作煞，因此，六煞也代表形状空间的意思。六煞磁场的人，心思比较细腻，在交际方面，很会投其所爱好，八名玲珑。很有异性缘，容易出现偏桃花、三角恋，这也是六煞磁场被称为“桃花磁场”的原因。但是他们内心脆弱，会经常想入非非，浮想联翩，来自寻烦恼。同时，他们也有很高的要求，喜欢挑剔，甚至无理取闹，斤斤计较；不过，六煞磁场的很注重形象，是爱美一族，并且有魅力和气场，这也是他们交际能力好的一个原因。六煞磁场的表现优点特点：非常善于交际，有八面玲珑的处事绝学，且异性缘好，情感丰富，有情调不乏味需要注意：优柔寡断，犹豫不决，情感纠葛，情绪不安，抑郁，抗压能力较差财富方面：会赚钱，易通过人际关系获得财运，灵活变通，从而会有许多生财的机会，但是却守不住财富事业方面：服务行业，公关，中介搭桥，与女性相关行业情感方面：若是已婚的人，那么由于异性缘较好，难免夫妻感情会不和，会互相猜忌，容易出现三角恋，导致分裂，如果是无对象的，这类人虽然异性缘比较好，但是本身优柔寡断，最后能成的概率很小",
  "tianyi": "天生资质好、聪明、工作上易成为老板，或老板得力助手，带来财富与业绩，处在高尚的地位，正桃花，常助人急困，天生单纯，但易流于没有主见情况因为天性善良， 天医磁场代表的是，感情财富谁都喜欢，但是她的负面影响是血压问题，也要特别注意，所以不是天医磁场能量多就是好，请要特别注意，选错号都有可能造成婚变或是血压问题的。  天医磁场优点： 聪明善良，可成大事，可带来财运，婚姻，心胸开阔。 天医磁场缺点： 过度善良，不计较，因此容易被骗，因为一般天医的人不缺钱，所以往往对钱也没有概念，或者偶尔错失小财",
  "shengqi": "生气代表的是贵人乐天派，他的优点那就是对什么都不计较，会吸引贵人，每天都会很快乐，大大咧咧，遇到挫折都会觉得无所谓，没什么大不了的，就觉得那可以过去的都是应该的，这些都是生气的优点。他们的缺点那就是说太大大咧咧了，太不在意了，太不在乎，太随性了，遇到一些事情真的没有办法叫做随缘。但是生气磁场会因为自己的懒散，不争取会拖拉，最后失去了机会，失去了什么东西的时候，他也会来一句随缘，其实不是失去了，不是真的随缘",
  "yannian": "延年磁场的人，心地善良、爱打抱不平、总想保护弱小群体。作风强势、老大心态，如果是男性就是大男人主义，如果是女性就是女强人。他们判断力极强、不拘小节、有大将之风。同时，拥有领导能力，可独当一面，责任心和使命感也超强。此外，由于延年磁场个人能力强、抗压能力也强、再加上对他人办事不放心所以常常独自承担所有责任，凡事亲力亲为，身体和心理都容易过度疲劳。总之，延年磁场的人就是喜欢当老大、说一不二。",
  "wugui": "最有才华也最不稳定，反应快际遇波折。五鬼数字磁场特点：擅于反向思考，智商非常高，鬼点子多，这样数字类型的人均是鬼才,因为他们喜欢样样都钻研，但同时他们个性阴沉，特立独行，行事令人捉摸不定，有双重人格，常有血光之灾,内心脆弱常走极端，是个很现实的人，婚姻上常造成离异和分手等不美满情形，或有外遇及成为第三者等，较不定性的类型，随时寻找改变，不循正道，不走正统的特性明显，与人相处易打成一片但内心城府极深，易憎恨他人，并且戒备心较强，不容易相信他人。有才华是好事，但也在五鬼磁场的人身上，都可看到情感和人生的失落起伏，由于五鬼磁场在情感上，认为求变是理所当然的，但又极度缺乏安全感，要抓住他们的心事有点困难的，五鬼磁场的人她的怀疑心很重",
  "huohai": "易和人吵架、口才虽好但是口服心不服，爱钻牛角尖，工作上常冲动做事，逞强脾气差，喜成话题人物，容易逞口舌之快，八面玲珑，花言巧语、说话夸大不实，嘴硬但内心又脆弱不堪一击，身体虚弱，比较有先天性的疾病，易为小事产生口角。鲜少有好的事发生在祸害磁场的，但如果跟有其他的吉星组合，就有不同看法。 祸害磁场的优点： 口若悬河滔滔不绝，舌灿莲花，举凡是能言善道都是祸害磁场的专长，只要是靠嘴巴吃饭的，大都是离不开祸害磁场的，是其他数字磁场望尘莫及的。 祸害磁场的缺点： 因脾气暴躁而引起的口舌是非，在人际关系上，较不讨人喜欢，易体弱多病，只要是进入祸害数字磁场理的运势时，几乎是不分男女，在这时间点你的身体状况已没有以前来的好了，若是年轻人，也比较容易体弱多病",
  "fuwei": "伏位磁场善于忍耐和等待，若是上天能够给予好的机遇，会爆发出强大的力量，得到渴望已久的成功或幸福。但有时又容易对未知的环境或机会过分谨慎，被动保守，不敢行动，缺少安全感，怕冒险，怕受伤害的内心矛盾和犹豫，导致错失良好的契机。伏位现象常有蓄势待发，状况延续，卧虎藏龙等状况出现"
}

export const doEnergyFlow = () => {

}

export const analyseNumber = (num) => {

  const chunks = breakNumber(num);
  const commentary = []
  const badEndingNumber = !GOOD_NUMBER.includes(chunks[chunks.length - 1].num)
  const has91 = num.includes('91') || num.includes('19')

  const energyChart = getEnergyFlow(chunks)
  const { yannian, fuwei, shengqi, tianyi, huohai, wugui, liusha, jueming } = energyChart
  const hasYannian = !!chunks.find(e => e.type === 'yannian')
  const hasTianyi = !!chunks.find(e => e.type === 'tianyi')
  const hasShengqi = !!chunks.find(e => e.type === 'shengqi')


  if (num.includes('0')) {
    commentary.push('Avoid Zero')
  }

  if (badEndingNumber) {
    commentary.push('Bad Energy Number at the end.')
  }

  if (has91) {
    commentary.push('91, 19 not suitable for women.')
  }

  if (!hasShengqi) {
    const values = getNumberEnergy('shengqi').join(', ')
    commentary.push(`No Shengqi (${values}) in the number`)
  }

  if (!hasTianyi) {
    commentary.push('No Tianyi in the number')
  }

  if (!hasYannian) {
    commentary.push('No Yannian in the number')
  }

  return {
    commentary,
    chunks,
    analysis: doAnalysis(chunks),
    energyFlow: {
      yannian,
      fuwei,
      shengqi,
      tianyi,
      huohai,
      wugui,
      liusha,
      jueming
    },
  }
}

export const checkNumber = (num) => {

  const WATER = 'blue'
  const EARTH = '#eee600'
  const WOOD = 'green'
  const METAL = '#757575'
  const FIRE = 'red'

  const colorNumberMap = {
    1: {
      color: METAL,
      element: 'metal'
    },
    2: { color: WATER, element: 'water' },
    3: { color: FIRE, element: 'fire' },
    4: { color: WOOD, element: 'wood' },
    5: { color: EARTH, element: 'earth' },
    6: { color: METAL, element: 'metal' },
    7: { color: WATER, element: 'water' },
    8: { color: FIRE, element: 'fire' },
    9: { color: WOOD, element: 'wood' },
    'earth': { color: EARTH, element: 'earth' },
    'water': { color: WATER, element: 'water' },
    'wood': { color: WOOD, element: 'wood' },
    'metal': { color: METAL, element: 'metal' },
    'fire': { color: FIRE, element: 'fire' },
  }
  return colorNumberMap[num] || 'black'
}








