import * as React from "react"
import BaseChip from '@mui/material/Avatar';
import { checkNumber } from '../library/number'

export const NumberChip = ({ children, num, size, styleProps }) => {
    let props = {}
    if (size) {
        props = {
            width: size,
            height: size,
            ...styleProps
        }
    }
    return <BaseChip sx={{  bgcolor: checkNumber(num).color, ...props }}> {children} </BaseChip>
}